import Cookies from 'js-cookie';
import { getOtherTicket } from "@/utils/common";

const Ticket = 'minicreate-Ticket';
const domain = location.host.split('.').slice(-3).join('.')

const DOMAIN = '.' + domain
const UPPER_DOMAIN = domain

export function getToken() {
  return Cookies.get(Ticket);
}

export function setToken(token) {
  Cookies.set(getOtherTicket(), token, {path: '/', domain: '.qq.com'})
  return Cookies.set(Ticket, token, { path: '/', domain: DOMAIN, expires: 3 });
}

export function removeToken() {
  // Cookies.remove('openid_ide', { path: '/', domain: DOMAIN });
  // Cookies.remove('access_token_ide', { path: '/', domain: DOMAIN });
  Cookies.remove(Ticket, { path: '/', domain: ".qq.com" });
  Cookies.remove(Ticket, { path: '/', domain: "qq.com" });
  Cookies.remove(Ticket, { path: '/', domain: UPPER_DOMAIN });
  Cookies.remove(getOtherTicket(), {path: '/', domain: '.qq.com'})
  Cookies.remove(getOtherTicket(), {path: '/', domain: 'qq.com'})
  Cookies.remove(getOtherTicket(), {path: '/', domain: UPPER_DOMAIN})
  return Cookies.remove(Ticket, { path: '/', domain: DOMAIN });
}

export function getCookie(key, config) {
  return Cookies.get(key, config);
}
export function setCookie(key, value) {
  return Cookies.set(key, value);
}
export function removeCookie(key) {
  return Cookies.remove(key);
}